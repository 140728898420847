import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js';
window.choices = Choices;


// Connects to data-controller="multisel"
export default class extends Controller {
  connect() {
    const element = document.querySelector('#pickuser');
    const choices = new Choices(element, {
      removeItems: true,
      removeItemButton: true,
      placeholder: true,
    });
  }
}
